import React from "react";
import { Box } from "rebass/styled-components";
import Page from "../templates/Page";
import Container from "../components/UI/Container";
import { useStaticQuery, graphql } from "gatsby";

export default () => {
  const { markdownRemark } = useStaticQuery(
    graphql`
      query {
        markdownRemark(frontmatter: { title: { eq: "legals" } }) {
          frontmatter {
            name
            url
            address
            publication
            url
            mail
            phone
          }
        }
      }
    `
  );
  const legals = markdownRemark.frontmatter || {};
  return (
    <Page title="Mentions Legales">
      <Box py={10}>
        <Container>
          <h1 className="title-underlined text-upper">Mentions Legales</h1>

          <p>
            Les mentions légales obligatoires concernant {legals.url} sont indiquées sur cette page
            conformément à la loi pour la confiance dans l’économie numérique (LCEN) de juin 2004 et
            la loi RGPD du 20 juin 2018 sur la protection des données.
          </p>

          <h3>1. Identification de l’éditeur</h3>
          <p>Nom de l’entreprise: {legals.name}</p>
          <p>
            Adresse de l&apos;entreprise : {legals.address}
            <br />
            Numéro de téléphone : {legals.phone}
            <br />
            Adresse email : {legals.mail}
          </p>

          <p>
            Directeur de la Publication : Au sens de l’article 93-2 de la loi n° 82-652 du 29
            juillet 1982.
          </p>
          <p>{legals.publication}</p>
          <h3>2. Hébergeur</h3>

          <p>La Fabrik</p>
          <p>33 ter rue Jean-Jaurès - 98 800 Nouméa - Nouvelle Calédonie</p>
          <p>Mail : support[at]la-fabrik.nc</p>
          <p>Tél. : + 687 26 39 04</p>
          <h3>3. Conditions générales d’utilisation du site et des services proposés.</h3>
          <p>
            L’utilisation du site {legals.url} implique l’acceptation pleine et entière des
            conditions générales d’utilisation ci-après décrites. Ces conditions d’utilisation sont
            susceptibles d’être modifiées ou complétées à tout moment.
          </p>
          <p>
            Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour
            raison de maintenance technique peut être toutefois décidée par l&apos;équipe de{" "}
            {legals.name}, qui s’efforcera alors de communiquer préalablement aux utilisateurs les
            dates et heures de l’intervention.
          </p>
          <p>
            Le site {legals.url} est mis à jour régulièrement par son équipe. De la même façon, les
            mentions légales peuvent être modifiées à tout moment : elles s’imposent néanmoins à
            l’utilisateur qui est invité à s’y référer le plus souvent possible afin d’en prendre
            connaissance.
          </p>
          <h3>4. Description des services fournis.</h3>
          <p>
            Le site {legals.url} a pour objet de fournir une information concernant l’ensemble des
            activités de l’Association.
          </p>
          <p>
            {legals.name} s’efforce de fournir sur son site des informations aussi précises que
            possible. Toutefois, il ne pourra être tenue responsable des omissions, des
            inexactitudes et des carences dans la mise à jour, qu’elles soient de son fait ou du
            fait des tiers partenaires qui lui fournissent ces informations.
          </p>
          <p>
            Toutes les informations indiquées sur le site {legals.url} sont données à titre
            indicatif, et sont susceptibles d’évoluer. Par ailleurs, les renseignements figurant sur
            le site {legals.url}
            ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été
            apportées depuis leur mise en ligne.
          </p>
          <h3>5. Limitations contractuelles sur les données techniques.</h3>
          <p>
            Le site utilise la technologie JavaScript. Le site Internet ne pourra être tenu
            responsable de dommages matériels liés à l’utilisation du site. De plus, l’utilisateur
            du site s’engage à accéder au site en utilisant un matériel récent, ne contenant pas de
            virus et avec un navigateur de dernière génération mis-à-jour.
          </p>
          <h3>6. Propriété intellectuelle et contrefaçons.</h3>
          <p>
            {legals.name} est propriétaire des droits de propriété intellectuelle ou détient les
            droits d’usage sur tous les éléments accessibles sur le site, notamment les textes,
            images, graphismes, logo, icônes, sons, logiciels.
          </p>
          <p>
            Toute reproduction, représentation, modification, publication, adaptation de tout ou
            partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est
            interdite, sauf autorisation écrite préalable de : La direction.
          </p>
          <p>
            Toute exploitation non autorisée du site ou de l’un des éléments qu’il contient sera
            considérée comme constitutive d’une contrefaçon et poursuivie conformément aux
            dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle.
          </p>
          <h3>7. Limitations de responsabilité.</h3>
          <p>
            {legals.name} ne pourra être tenu responsable des dommages directs et indirects causés
            au matériel de l’utilisateur, lors de l’accès au site {legals.url}, et résultant soit de
            l’utilisation d’un matériel ne répondant pas aux spécifications indiquées au point 4,
            soit de l’apparition d’un bug ou d’une incompatibilité.
          </p>
          <p>
            {legals.name} ne pourra également être tenue responsable des dommages indirects (tels
            par exemple qu’une perte de marché ou perte d’une chance) consécutifs à l’utilisation du
            site
            {legals.url}
          </p>
          <p>
            Des espaces interactifs (possibilité de poser des questions dans l’espace contact) sont
            à la disposition des utilisateurs. {legals.name} se réserve le droit de supprimer, sans
            mise en demeure préalable, tout contenu déposé dans cet espace qui contreviendrait à la
            législation applicable en France, en particulier aux dispositions relatives à la
            protection des données. Le cas échéant, {legals.name} se réserve également la
            possibilité de mettre en cause la responsabilité civile et/ou pénale de l’utilisateur,
            notamment en cas de message à caractère raciste, injurieux, diffamant, ou
            pornographique, quel que soit le support utilisé (texte, photographie…).
          </p>
          <h3>8. Gestion des données personnelles.</h3>
          <p>
            En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6
            janvier 1978, la loi n° 2004-801 du 6 août 2004, l’article L. 226-13 du Code pénal et la
            Directive Européenne du 24 octobre 1995 et la Loi n°2018-493 du 20 juin 2018.
          </p>
          <p>
            A l’occasion de l’utilisation du site {legals.url}, peuvent êtres recueillies : l’URL
            des liens par l’intermédiaire desquels l’utilisateur a accédé au site {legals.url}, le
            fournisseur d’accès de l’utilisateur, l’adresse de protocole Internet (IP) de
            l’utilisateur.
          </p>
          <p>
            En tout état de cause {legals.name} ne collecte des informations personnelles relatives
            à l’utilisateur que pour le besoin de certains services proposés par le site{" "}
            {legals.url}. L’utilisateur fournit ces informations en toute connaissance de cause,
            notamment lorsqu’il procède par lui-même à leur saisie. Il est alors précisé à
            l’utilisateur du site {legals.url} l’obligation ou non de fournir ces informations.
          </p>
          <p>
            Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier
            1978 relative à l’informatique, aux fichiers et aux libertés ainsi que la Loi n°2018-493
            du 20 juin 2018, tout utilisateur dispose d’un droit d’accès, d’opposition, de
            rectification, d’oubli, de portabilité, de refus du profilage, de limitation du
            traitement, et enfin de mort numérique, aux données personnelles le concernant, en
            effectuant sa demande écrite et signée, accompagnée d’une copie du titre d’identité avec
            signature du titulaire de la pièce, en précisant l’adresse à laquelle la réponse doit
            être envoyée.
          </p>
          <p>
            Aucune information personnelle de l’utilisateur du site
            {legals.url} n’est publiée à l’insu de l’utilisateur, échangée, transférée, cédée ou
            vendue sur un support quelconque à des tiers. Seule l’hypothèse du rachat de{" "}
            {legals.name} et de ses droits permettrait la transmission des dites informations à
            l’éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et
            de modification des données vis à vis de l’utilisateur du site {legals.url}.
          </p>
          <p>
            Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998
            transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des
            bases de données transposée par l’ordonnance du 12 décembre 2018 et applicable en
            Océanie française depuis le 1er juin 2019.
          </p>
          <h3>9. Liens hypertextes et cookies.</h3>
          <p>
            Le site {legals.url} contient un certain nombre de liens hypertextes vers d’autres
            sites, mis en place avec l’autorisation de la direction. Cependant, {legals.name} n’a
            pas la possibilité de vérifier le contenu des sites ainsi visités, et n’assumera en
            conséquence aucune responsabilité de ce fait. La navigation sur le site {legals.url} est
            susceptible de provoquer l’installation de cookie(s) sur l’ordinateur de l’utilisateur.
            Un cookie est un fichier de petite taille, qui ne permet pas l’identification de
            l’utilisateur, mais qui enregistre des informations relatives à la navigation d’un
            ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation
            ultérieure sur le site, et ont également vocation à permettre diverses mesures de
            fréquentation.
          </p>
          <p>Liste des cookies utilisés sur le site:</p>
          <p>Google Analytics</p>

          <p>
            Le refus d’installation d’un cookie peut entraîner l’impossibilité d’accéder à certains
            services. L’utilisateur peut toutefois configurer son ordinateur de la manière suivante,
            pour refuser l’installation des cookies :
          </p>
          <p>
            Sous Internet Explorer : onglet outil (pictogramme en forme de rouage en haut a droite)
            / options internet. Cliquez sur Confidentialité et choisissez Bloquer tous les cookies.
            Validez sur Ok. Sous Firefox : en haut de la fenêtre du navigateur, cliquez sur le
            bouton Firefox, puis aller dans l’onglet Options. Cliquer sur l’onglet Vie privée.
            Paramétrez les Règles de conservation sur : utiliser les paramètres personnalisés pour
            l’historique. Enfin décochez-la pour désactiver les cookies.
          </p>
          <p>
            Sous Safari : Cliquez en haut à droite du navigateur sur le pictogramme de menu
            (symbolisé par un rouage). Sélectionnez Paramètres. Cliquez sur Afficher les paramètres
            avancés. Dans la section « Confidentialité », cliquez sur Paramètres de contenu. Dans la
            section « Cookies », vous pouvez bloquer les cookies.
          </p>
          <p>
            Sous Chrome : Cliquez en haut à droite du navigateur sur le pictogramme de menu
            (symbolisé par trois lignes horizontales). Sélectionnez Paramètres. Cliquez sur Afficher
            les paramètres avancés. Dans la section « Confidentialité », cliquez sur préférences.
            Dans l’onglet « Confidentialité », vous pouvez bloquer les cookies.
          </p>
          <h3>10. Droit applicable et attribution de juridiction.</h3>
          <p>
            Tout litige en relation avec l’utilisation du site {legals.url}
            est soumis au droit français. Il est fait attribution exclusive de juridiction des
            tribunaux de droit commun, et des tribunaux de Nouméa en cas de litige avec un
            professionnel.
          </p>
          <h3>11. Les principales lois concernées.</h3>
          <p>
            Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004
            relative à l’informatique, aux fichiers et aux libertés.
          </p>
          <p>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l’économie numérique.</p>
          <p>
            Loi n°2018-493 du 20 juin 2018 relative à la protection des données personnelles dite
            RGPD.
          </p>
          <h3>12. Lexique.</h3>
          <p>Utilisateur : Internaute se connectant, utilisant le site susnommé.</p>
          <p>
            Données à caractère personnel : “toute information se rapportant à une personne physique
            identifiée ou identifiable (ci-après dénommée &quot;personne concernée&quot;); est
            réputée être une &quot;personne physique identifiable&quot; une personne physique qui
            peut être identifiée, directement ou indirectement, notamment par référence à un
            identifiant, tel qu&apos;un nom, un numéro d&apos;identification, des données de
            localisation, un identifiant en ligne, ou à un ou plusieurs éléments spécifiques propres
            à son identité physique, physiologique, génétique, psychique, économique, culturelle ou
            sociale. (Article 4 de la loi n°2018-493 du 20 juin 2018)
          </p>
        </Container>
      </Box>
    </Page>
  );
};
